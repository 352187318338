const utils = require('../utils');
import $ from "jquery";

class Shop {

    constructor(el) {
        this.el = el;
        this.init();
    }

    init(){
        let shopEl = this;
        if(this.el && this.el.length > 0){
            //get basket content
            let bl = this.el[0];
            let href = bl.dataset.baskethandle;
            href += "?cmd=getbasket";
            this.basketCommURL(href);

            //attach interaction
            $(this.el).click(function(){
                shopEl.basketComm($(this),"addtobasket");
                let t = $(this);
                let p = t.parent();
                p.find('.infobadge').fadeIn();
//            p.find('.infobadge').fadeTo(300,1);
            });
        }

        this.verifyPaymentAndPersistOrderIfNeeded();
        this.setFormDestination();

        let mainwrap = $("#content-main-wrap");
        $(mainwrap).on("click",".basket-remove",function(){
            let t  = $(this);
            t.closest(".cd-album").fadeTo(1,0.3);
            shopEl.basketComm(t,"removefrombasket", shopEl.removeFromBasketList);
        }).on("mouseenter",".basket-remove",function(){
            let t  = $(this);
            t.closest(".cd-album").addClass("blurred");
        }).on("mouseleave",".basket-remove",function(){
            $(".cd-album.blurred").removeClass("blurred");
        }).on("change",".basket-count",function(){
            let t = $(this);
            let v  = t.val();
            if(isNaN(v) || v<1||v>10){v=1;}
            t.val(v);
            t = t.parent().find(".basket-remove");
            shopEl.basketComm(t,"updatebasket", shopEl.refreshBasketList,v);

        }).on("change","#shipmentgroup input",function(){
            let t = $(this);
            let v  = t.val();
            shopEl.setShipmentTo(v);

        }).on("change","#paymentgroup input",function(){
            let t = $(this);
            let v  = t.val();
            shopEl.setPayment(v);
            shopEl.setFormDestination();
        }).on("change","select.countrylist:visible",function(){
            let c = $("select.countrylist:visible").last().val();
            if(c.toLowerCase()!="ch"){
                $("#shipment_int").click();
            }else{
                $("#shipment_ch").click();
            }
        });

    }


    getSelectedPayment(){
        let r = "INV";
        let form = $(".form-wrapper form");
        if(form.length>0){
            let pay = form.find("#paymentgroup input:checked");
            if(pay.length>0){
                r = pay.val();
            }
        }
        return r;
    }

    setFormDestination(){
        let form = $(".form-wrapper form");
        if(form.length>0){
            if(this.getSelectedPayment()=="CC"){
                let PF_ACTION_URL = $("#PF_ACTION_URL").val();
                form.attr("action",PF_ACTION_URL);
            }else{
                form.attr("action","");
            }
        }
    }

    setShipmentTo(v){
        let url = utils.getCurrentURL()+"?shipment="+v+"&ck="+new Date().getTime();
        $.get(url,function(data){
            if(v=="CH"){
                $("select.countrylist:visible").last().val("CH");
            }
            let shippingprice = $(data).find(".total-shipment");
            $( ".total-shipment" ).html(shippingprice.html());
            let grandtotal = $(data).find(".total-total");
            $( ".total-total" ).html(grandtotal.html());
        });
    }

    setPayment(v){
        let url = utils.getCurrentURL()+"?payment="+v+"&ck="+new Date().getTime();
        $.get(url,function(data){
            try{
                $("#payment_mailinfo").val($(data).find("#payment_mailinfo").val());
            }catch (e){}

        });
    }

    removeFromBasketList(id){
        let t = $("#"+id);
        if(t.length>0){
            t.closest(".cd-album").fadeTo(200,0.01,function(){});
            //this.refreshBasketList();$
            // duplicated code refreshBasketList
            let url = utils.getCurrentURL()+"?ck="+new Date().getTime();
            $.get( url, function( data ) {
                let content = $(data).find("#content-main-wrap");
                $( "#content-main-wrap" ).html(content.html());
            });
        }
    }


    refreshBasketList(){
        let url = utils.getCurrentURL()+"?ck="+new Date().getTime();
        $.get( url, function( data ) {
            let content = $(data).find("#content-main-wrap");
            $( "#content-main-wrap" ).html(content.html());
        });
    }

    basketComm(element,cmd,callback,count){
        let shopEl = this;
        let id = element.attr("id");
        let href = element.data("baskethandle");
        href += "?cmd="+cmd+"&cdid="+id+"&ck="+new Date().getTime();
        if(count && !isNaN(count)){
            href += "&count="+count;
        }
        $.get(href,function(data){
            if(callback){
                callback(id);
            }else{
                shopEl.basketCommResponse(data);
            }
//            concertlist.html();
        });

    }

    basketCommURL(href){
        let shopEl = this;
        href += "&ck="+new Date().getTime();
        $.get(href,function(data){
            shopEl.basketCommResponse(data);
        });
    }

    basketCommResponse(data){
        let response = $.trim(data);
        let responseObject = eval('(' + response + ')');
        if(responseObject['basketlink']){
            let bl = $("#basketlink");
            if(bl.length>0){bl.remove();}
            $("#content-side-nav").append(responseObject['basketlink']);
        }

    }

    verifyPaymentAndPersistOrderIfNeeded(){
        let shopEl = this;
        let form = $(".form-wrapper form");
        form.submit(function(){
            if(shopEl.getSelectedPayment()=="CC"){
                let PF_ACTION_URL = $("#PF_ACTION_URL").val();
                $.ajax({
                    url: window.location.href,
                    type: 'get',
//                    type: 'post',
                    data: form.serialize(),
                    async: false,
                    success: function(data) {
                    }
                });
            }
        });
    }
}
export default Shop;
