const utils = require('./utils');

const navigation = {
    init() {
        this.initMainNavigation();
    },

    initMainNavigation(){
        let mainNav = $("#main-nav");
        mainNav.find("ul li a").click(function(e){
            // if(!utils.isMobile()){
            if(!utils.isMobileOrMedium()){
                let a = $(this);
                let h = a.attr("href");
                let c = utils.getCurrentURL();
                if(a && c && c.indexOf(h)>0){
                    e.preventDefault();
                    if(utils.getParameterByName("cdf")!=""){
                        window.location.href = h+"#wrapper";
                    }else{
                        $("#main-trigger.close, #arrow_go_down").click();
                    }
                }
            }
        });

        // if (utils.isMobile()){
        if (utils.isMobileOrMedium()){
            mainNav.on('click', 'li.has-ul-child:not(.active-only-open)', function (e) {
                e.preventDefault();
                let arrow = $(this).find('.open-subnav').first();

                if ($(this).hasClass('open')){
                    $(this).find('ul').first().slideUp( 400, () => {
                        $(arrow).html("&#x25b6;&#xfe0e;");
                        $(arrow).removeClass('open');
                        $(this).removeClass('active active-only-open');
                        $(this).find('li.has-ul-child .open-subnav').removeClass('open');
                    });
                } else {
                    $(arrow).html("&#9660;");
                    $(arrow).addClass('open');
                    $(this).addClass('active active-only-open');
                    $(this).find('ul').first().slideDown();
                }
            });

            mainNav.on('click', 'li.has-ul-child.active-only-open .open-subnav', function () {
                $(this).parent().find('ul').first().slideUp( 400, () => {
                    $(this).html("&#x25b6;&#xfe0e;");
                    $(this).removeClass('open');
                    $(this).parent().removeClass('active active-only-open');
                    $(this).find('li.has-ul-child .open-subnav').removeClass('open');
                });
            });

            $("#main-trigger").on('click', function () {
                if ($(this).hasClass('close')) {
                    mainNav.find('li.has-ul-child.active-only-open').each(function () {
                        $(this).removeClass('active active-only-open');
                        $(this).find('ul').first().css('display', '');
                        $(this).find('.open-subnav').each(function () {
                            $(this).html("&#x25b6;&#xfe0e;");
                            $(this).removeClass('open');
                        })
                    })
                }
            });


        }

        mainNav.find("a[href^='#']").not(".newsletter-trigger").click(function() {
            $("#main-trigger.close").click();
        });
    }
};

module.exports = navigation;
