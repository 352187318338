require('jquery');
let contentLeft;
let contentRight;
// let servTrig;
let mainTrig;

let wrapOffset;
let mainScroll;
let siteTitleScroll;
let siteTitleScrollFix;
// let servScroll;
let contentRightScroll;
let contentLeftScroll=0;
let hasFullsizeHeader;
let navHeight;

const utils = {

    checkIfEditMode() {
        sukoaProject.isEditMode = document.body.classList.contains('editmode');
    },

    dcmadr(nnnn) {
        let a = "";
        for (let i = 0, m = nnnn.length; i < m; i++) {
            if (i % 3 == 0) {
                a += String.fromCharCode(nnnn.substr(i, 3));
            }
        }
        location.href = (a);
    },

    detectIE() {
        let ua = window.navigator.userAgent;

        let msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        let trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            let rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        let edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // IE 12 (aka Edge) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    },

    each(list, fn) {
        if(list && fn) {
            for(let i = 0; i < list.length; i++) {
                fn(list[i]);
            }
        }
    },

    getCurrentURL : function(excludeParams){
        let url = window.location.href;
        if(excludeParams){
            let i = url.indexOf("?");
            if(i>-1){
                url = url.substring(0,i);
            }
            let j = url.indexOf("#");
            if(j>-1){
                url = url.substring(0,j);
            }
        }
        return url;
    },

    getParameterByName: function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        let regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },

    getWrapOffset(){
        return document.getElementById('wrapper').offsetTop;
    },

    getCookie(name) {
        let value = "; " + document.cookie;
        let parts = value.split("; " + name + "=");
        if (parts.length == 2) return parts.pop().split(";").shift();
    },

    isMobile(){
        return window.innerWidth < 641;
    },

    isMobileOrMedium(){
        return window.innerWidth < 1024;
    },

    hasFullsizeHeader(){
        let body = $("body");
        hasFullsizeHeader = ( body.hasClass('langlevel') || body.hasClass('langlevel-forwarded') || body.hasClass('is-detail') );
        return hasFullsizeHeader;
    },

    getNavHeight(){
        navHeight = document.getElementById('site-title-row').getBoundingClientRect().height;
        return navHeight;
    },



    scrollCheck(elScroll, el, cl, callback){
        cl = cl || 'scroll-reached'; //default class if undefined
        if (window.pageYOffset >= elScroll) {
            // if (!el.data(cl)) {
            //     el.data(cl,elScroll);
            // }
            el.classList.add(cl);
            if(typeof(callback) == "function") callback();
            return true;
        } else {
            // el.data(cl,'');
            el.classList.remove(cl);
            if(typeof(callback) == "function") callback();
            return false
        }
    },

    updateScrollPositions(){
        let wrap = document.getElementById('wrapper');
        if (wrap){
            wrapOffset = wrap.offsetTop;
            contentLeft = document.getElementsByClassName("content-left")[0];
            contentRight = document.getElementsByClassName("content-right")[0];
            // servTrig = document.getElementById("services-trigger");
            mainTrig = document.getElementById("main-trigger");

            // let winHeight = window.innerHeight;
            // if(wrapOffset < winHeight) {
            //     wrapOffset = winHeight;
            // }

            // mainScroll = wrapOffset - (mainTrig.offsetTop + mainTrig.offsetHeight);
            siteTitleScroll = wrapOffset - 75;
            siteTitleScrollFix = wrapOffset;
            // servScroll = wrapOffset - (servTrig.offsetTop + servTrig.offsetHeight);
            // contentRightScroll = (hasFullsizeHeader) ? wrapOffset : wrapOffset - (((window.innerHeight - navHeight) / 3) + navHeight);
            contentRightScroll = (hasFullsizeHeader) ? wrapOffset : (wrapOffset - navHeight);
            if(contentLeft && contentRight){
                contentLeftScroll = (contentLeft.getBoundingClientRect().top +  window.scrollY) + contentLeft.offsetHeight - contentRight.offsetHeight;
            }
        }
    },

    updateScrollStyles(){
        let wrap = document.getElementById('wrapper');
        if (wrap) {
            let siteTitle = document.getElementById("site-title-row");

            let contentLeft = document.getElementsByClassName("content-left")[0];
            let contentRight = document.getElementsByClassName("content-right")[0];

            utils.scrollCheck(siteTitleScroll, siteTitle);

            if (contentLeft && contentRight) {
                // console.log("contentLeft.offsetHeight: "+contentLeft.offsetHeight);
                // console.log("contentRight.offsetHeight: "+(contentRight.offsetHeight + 100));
                if (contentLeft.offsetHeight > (contentRight.offsetHeight + 100) && window.innerHeight > (contentRight.offsetHeight + 100)) { //add 100px to make sure there are no small moves
                    if (!utils.isMobile()) {
                        utils.scrollCheck(contentRightScroll, contentRight);
                        utils.scrollCheck(contentLeftScroll, contentRight, 'scroll-bottom', function () {
                            if (contentRight.classList.contains('scroll-bottom')) {
                                contentRight.style.top = (contentLeftScroll - contentRightScroll) + "px";
                            } else {
                                contentRight.style.top = "";
                            }
                        });
                    } else {
                        contentRight.classList.remove('scroll-reached');
                        contentRight.classList.remove('scroll-bottom');
                        contentRight.style.top = "";
                    }
                } else {
                    contentRight.classList.remove('scroll-reached');
                    contentRight.classList.remove('scroll-bottom');
                    contentRight.style.top = "";
                }
            }
        }
    },

    fullscreenSlider(){

        // Fullscreen image slider
        $('.bg-slider-container.images').each(function() {
            let t = $(this);

            let slider = $(this).find('.bg-slider');
            let sliderNav = $(this).find(".slider-nav");
            let prev = sliderNav.find('.arrow_left');
            let next = sliderNav.find('.arrow_right');
            let slides = slider.find('img').map(function () {
                return {src: this.src}
            }).get();

            if (slides.length > 0){
                slider.vegas({
                    slides: slides,
                    timer: false,
                    delay: slider.data('duration')
                });
            }

            //console.log(slider.data('duration'));
            next.unbind( "click" );
            prev.unbind( "click" );
            next.on("click", function (e) {
                e.preventDefault();
                slider.vegas('next');
            });
            prev.on("click", function (e) {
                e.preventDefault();
                slider.vegas('previous');
            });



        });
        let bgmovieSoundBtns = $("#bgmovie-sound-btns");
        let backgroundVideo = document.getElementById("background_video");
        bgmovieSoundBtns.find(".bgmovie-sound").click(function(){
            let mu = $("#mute-bgmovie");
            let um = $("#unmute-bgmovie");
            let sb = $(this);
            let id = sb.attr("id");
            if(id=="mute-bgmovie"){
                mu.hide();
                um.show();
                backgroundVideo.muted = true;
            }else{
                um.hide();
                mu.show();
                backgroundVideo.muted = false;
            }
        });
    },

    imageSlider(){

        // Content image slider
        $('.image-slider-container.images').each(function() {
            let t = $(this);

            const container = $(this);
            const aspect = parseFloat(container.data('aspect'));
            const slider = container.find('.image-slider');
            const sliderInfo = container.find('.image-slider-info');
            const sliderNav = container.find(".image-slider-nav");
            const prev = sliderNav.find('.arrow_left');
            const next = sliderNav.find('.arrow_right');
            let slides = slider.find('img').map(function () {
                return {src: this.src}
            }).get();


            const adjustHeight = () => {
                const newHeight = container.width() * aspect;
                console.log( `Aspect: ${aspect}` );
                console.log( `Container: ${container.width()} * ${aspect} = ${newHeight}` );
                container.height( newHeight );
                slider.height( newHeight );
            }

            adjustHeight();
            window.onresize = () => {
                adjustHeight();
            };

            const updateInfo = (index) => {
                const imgs = slider.find('.vegas-wrapper img');
                const currImg = imgs.eq(index);
                const copyright = currImg.data('copyright');
                const caption = currImg.data('caption');

                let info = '';
                if (caption && copyright){
                    info = `${caption} | ©&nbsp;${copyright}`
                } else if (caption) {
                    info = `${caption}`;
                } else if (copyright) {
                    info = `©&nbsp;${copyright}`;
                }

                if (info){
                    sliderInfo.css('display', 'block').html(info);
                } else {
                    sliderInfo.css('display', 'none')
                }
            }

            if (slides.length > 0){
                slider.vegas({
                    slides: slides,
                    transition: 'slideLeft2',
                    cover: false,
                    timer: false,
                    delay: slider.data('duration'),
                    init: (globalSettings) => {
                        console.log("Init imageSlider");
                        updateInfo(globalSettings.slide);

                        //detect touch swipes
                        container[0].addEventListener('touchstart', (e) => {
                            window.xDown = e.touches[0].clientX;
                            window.yDown = e.touches[0].clientY;
                        });
                        container[0].addEventListener('touchmove', (e) => {
                            if ( ! window.xDown || ! window.yDown ) {
                                return;
                            }
                            let xDiff = window.xDown - e.touches[0].clientX;
                            let yDiff = window.yDown - e.touches[0].clientY;

                            if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
                                if ( xDiff > 0 ) {
                                    slider.vegas('options', 'transition', 'slideLeft2').vegas('next');
                                } else {
                                    slider.vegas('options', 'transition', 'slideRight2').vegas('previous');
                                }
                            }
                            window.xDown = null;
                            window.yDown = null;
                        });
                    },
                    walk: function (index, slideSettings) {
                        console.log("Slide index " + index);
                        updateInfo(index);
                    }
                });
            }

            //console.log(slider.data('duration'));
            next.unbind( "click" );
            prev.unbind( "click" );
            next.on("click", function (e) {
                e.preventDefault();
                console.log("imageSlider: next")
                slider.vegas('options', 'transition', 'slideLeft2').vegas('next');
            });
            prev.on("click", function (e) {
                e.preventDefault();
                console.log("imageSlider: prev")
                slider.vegas('options', 'transition', 'slideRight2').vegas('previous');
            });


        });
    },

};

module.exports = utils;
