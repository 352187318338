const utils = require('../utils');
import $ from "jquery";
import powerTip from 'jquery-powertip';

class Concert {

    constructor(el) {
        this.el = el;
        this.id = el.id.replace("concert_list_", "");
        this.settings = document.getElementById("concert_list_settings_"+this.id);

        this.maxEntries = this.settings.dataset.maxEntries;
        this.link = this.settings.dataset.link;

        this.init();
    }

    init(){
        let concertEl = this;
        let concertList = $(this.el);
        let pageCount = utils.getParameterByName("p");
        pageCount= pageCount.length>0? parseInt(pageCount) : 1;
        $(concertEl.el).find('.content-left').removeClass('on-load');
        let concertTypeFilter = $(".concert-type-filter")[0];
        let expandConcertTypeFilter = document.getElementById("expand-concert-type-filter");
        if  (expandConcertTypeFilter && !concertTypeFilter.classList.contains("has-selected")){
            concertTypeFilter.classList.add("reduced");
            expandConcertTypeFilter.classList.remove("hide");
        }

        concertList.on('mouseenter mouseleave','.concert-description-wrapper,.btn.more',function(e){
            if($(this).hasClass("concert-description-wrapper")){
                $(this).parent().find(".btn.more").toggleClass("over");
            }else{
                $(this).parent().parent().find(".concert-description-wrapper").toggleClass("over");
            }
        });
        concertList.on('click','.concert-description-wrapper, .btn.more',function(e){
            e.preventDefault();
            let u = this.href;
            let t = window.pageYOffset;
            if (pageCount > 1){

                let param = "t="+t+"&p="+pageCount;
                if (u.indexOf("?")>= 0) {
                    let regexSkip = new RegExp("[\\?&]skiphead=([^&#]*)"), resultsSkip = regexSkip.exec(u);
                    u =  resultsSkip === null ? u+"&p="+pageCount : u.replace(resultsSkip[0], "?");
                    let regexP = new RegExp("[\\?&]p=([^&#]*)"), resultsP = regexP.exec(u);
                    u =  resultsP === null ? u+"&p="+pageCount : u.replace(resultsP[0], resultsP[0].replace(resultsP[1], pageCount));
                    let regexT = new RegExp("[\\?&]t=([^&#]*)"), resultsT = regexT.exec(u);
                    u =  resultsT === null ? u+"&t="+t : u.replace(resultsT[0], resultsT[0].replace(resultsT[1], t));
                } else{
                    u += "?"+param;
                }
            } else if (u.indexOf("&") === -1 && u.indexOf("?skiphead")>= 0){
                u = u.substring(0, u.indexOf("?skiphead"));
            }
            window.location.href = u;
        });

        //calendar interaction
        //still TODO
        // concertList.on('click','.calendar-display a',function(e){
        //     e.preventDefault();
        //     let t = $(this);
        //     concertEl.loadAndDisplaySelection(concertlist.link+t.attr("href"));
        // });

        //show more
        if(pageCount > 1){
            for (let i = 1; i < pageCount; i++){
                updatePaging();
            }
        }
        concertList.on('click','.show-more-concerts',function(){
            pageCount++;

            let u = window.location.href;
            if (u.indexOf("?")>= 0) {
                u = u.substring(0, u.indexOf("?"));
            }
            u += "?p=" + pageCount;

            history.pushState(null, null, u);

            updatePaging();
        });

        //filter entries
        concertList.on('click','.concert-type-filter li a',handleFilterClick);
        concertList.on('click','.calendar-display a',handleFilterClick);
        concertList.on('click','#expand-concert-type-filter',function(){
            concertTypeFilter.classList.remove("reduced");
            this.classList.add("hide");
            setTimeout(function(){
                concertEl.updateSidenav();
            }, 1100);
        });

        function updatePaging() {
            let moreentries = concertList.find(".concertlistentry:hidden").not(".inactive");
            if(moreentries.length>0){
                if(moreentries.length<=concertEl.maxEntries){
                    moreentries.slideDown(function() {
                        concertEl.updateSidenav();
                    });
                    $('.show-more-concerts').hide();
                }else{
                    moreentries.slice(0,concertEl.maxEntries).slideDown(function() {
                        concertEl.updateSidenav();
                    });
                }
            }
        }
        function handleFilterClick(e) {
            e.preventDefault();
            pageCount=1;
            let t = $(this);
            let href = t.attr("href");
            let selector = href.match(/(~.*~)/g);
            // let u = href;
            let u = concertEl.link;
            if (selector !== null) {
                u = u.replace(new RegExp(/\.html/), selector + ".html");
                // u = "http://localhost:8080"+u;
            }

            if (concertTypeFilter.classList.contains("reduced")){
                concertTypeFilter.classList.remove("reduced");
                setTimeout(function(){
                    concertEl.loadAndDisplaySelection(u, href);
                }, 100);
            } else {
                concertEl.loadAndDisplaySelection(u, href);
            }

        }

        //search entries
        concertList.on('submit','#concert-list-search-form',function(e){
            e.preventDefault();
            concertEl.startSearch();
        });
        concertList.on('click','#concert-list-search-options [type="radio"]',function(e){
            e.preventDefault();
            concertEl.startSearch();
        });
        concertList.on('click','#concert-list-search-submit',function(e){
            e.preventDefault();
            concertEl.startSearch();
        });
        concertList.on('keyup','#concert-list-search',function(e){
            e.preventDefault();
            let code = e.keyCode || e.which;
            if(code == 13) {
                concertEl.startSearch();
            }
        });
        concertList.on('focus','#concert-list-search',function(e){
            $("#concert-list-search-options").slideDown();
        });
        // concertList.on('focusout','#concert-list-search',function(e){
        //     $("#concert-list-search-options").slideUp();
        // });
    }

    startSearch(){
        let v = $("#concert-list-search").val();
        let a = $('input[name="isarchive"]:checked').val();
        let u = this.link+'?search='+v+'&isarchive='+a;
        this.loadAndDisplaySelection(u);
    }

    loadAndDisplaySelection(u, origUri){
        let concertEl = this;

        // if (u.indexOf("?")>= 0) {
        //     u += "&ck=" + new Date().getTime();
        // } else {
        //     u += "?ck=" + new Date().getTime();
        // }

        //make sure results are uncollapsed
        u += "&uncollapsed=yes";

        $(concertEl.el).find('.content-left').addClass('on-load');
        let t1 = new Date().getTime();
        const navHeight = utils.getNavHeight();
        $.get(u,function(data){
            let response = $($.trim(data));

            let t2 = new Date().getTime();
            let diff = 500 - (t2 - t1);
            if (diff < 0){
                diff=0;
            }
            setTimeout(function () {
                $(concertEl.el).html(response.html());

                console.log(response);
                console.log(response.html());

                $('.tooltip').powerTip();
                concertEl.updateSidenav();
                const isArchive = $('input[name="isarchive"]:checked').val();
                console.log(`isArchive: ${isArchive}`)
                if (isArchive === 'true'){
                    $(concertEl.el).find('.calendar-display').hide();
                }
                // $(window).scrollTop(utils.getWrapOffset());
                if ($('body').hasClass('langlevel') || $('body').hasClass('langlevel-forwarded')){
                    $(window).scrollTop(concertEl.el.offsetTop + window.innerHeight - (navHeight / 2));
                } else {
                    $(window).scrollTop(concertEl.el.offsetTop + (((window.innerHeight - navHeight) / 3) * 2) + (navHeight / 2));
                }

                $(concertEl.el).find('.content-left').removeClass('on-load');
            }, diff);

            //TODO update the location
            // let newUrl= window.location.href.replace(new RegExp("(~.*~)?\\.html"), u.match(new RegExp("~.*~"))+".html");
            if (origUri !== undefined) {
                history.pushState(null, null, origUri);
            }
        });
    }

    updateSidenav() {
        utils.updateScrollPositions();
    }

}
export default Concert;
