const utils = require('../utils');
import $ from "jquery";

class Player {

    constructor(el) {
        this.el = el;
        this.initFilter();

    }

    initFilter(){
        let videoFilter = this.el.getElementsByClassName("video-filter")[0];
        let videoEntries = this.el.getElementsByClassName("vimeo-player");
        videoFilter.addEventListener( 'change', function() {
            if(this.checked) {
                // Checkbox is checked..
                utils.each(videoEntries, videoEntry => videoEntry.parentElement.style.display = "block");
            } else {
                // Checkbox is not checked..
                utils.each(videoEntries, videoEntry => videoEntry.parentElement.style.display = "none");
            }
        });
        let audioFilter = this.el.getElementsByClassName("audio-filter")[0];
        let audioEntries = this.el.getElementsByClassName("sound-player");
        audioFilter.addEventListener( 'change', function() {
            if(this.checked) {
                // Checkbox is checked..
                utils.each(audioEntries, audioEntry => audioEntry.parentElement.style.display = "block");
            } else {
                // Checkbox is not checked..
                utils.each(audioEntries, audioEntry => audioEntry.parentElement.style.display = "none");
            }
        });
    }


}
export default Player;
