const utils = require('../utils');
import $ from "jquery";

class Teaser {

    constructor(el) {
        this.el = el;
        this.id = el.id.replace("teaser_", "");
        this.settings = document.getElementById("teaser_settings_"+this.id);

        if (this.settings){
            this.teasersList = $(this.settings).data('teasersList');
            this.externalLinkSvg = this.settings.dataset.externalLinkSvg;
            this.detailLabel = this.settings.dataset.detailLabel;

            this.init();
        }
    }

    init(){
        let teaserEl = this;

        let index = Math.floor((Math.random() * teaserEl.teasersList.length) + 0);

        let t = teaserEl.teasersList[index];

        let html = '';
        let hasImg;
        let hasFilm;

        if (t){
            if (t.title){
                html += '<h1 class="color-'+t.color+'">'+t.title+'</h1>\n';
                html += '<hr class="teaser-hr border-color-'+t.color+'">\n';
            }
            if (t.subtitle) {
                html += '<h2 class="color-'+t.color+'">'+t.subtitle+'</h2>\n';
            }
            if (t.listinfo) {
                html += '    <div class="description color-' + t.color + '">\n' +
                '       ' + t.listinfo + '    \n' +
                    '    </div>\n';
                // '    <div class="category color-'+t.color+'">\n' +
                // '        '+t.konzertformat+'\n' +
                // '    </div>\n';
            }

            if (t.left_btn_title.length > 0 && t.left_btn_link.length > 0){
                let isExternal = t.left_btn_link.startsWith('http');
                if (isExternal){
                    html += '<a class="btn more" target="_blank" href="'+t.left_btn_link+'">'+t.left_btn_title+teaserEl.externalLinkSvg+'</a>\n';
                } else {
                    html += '<a class="btn more" href="'+t.left_btn_link+'">'+t.left_btn_title+'</a>\n';
                }
            } else if (typeof t.detailLink !== 'undefined' && t.detailLink.length > 0){
                html += '<a class="btn more" href="'+t.detailLink+'">'+teaserEl.detailLabel+'</a>';
            }

            if (t.right_btn_title.length > 0 && t.right_btn_link.length > 0){
                let isExternal = t.right_btn_link.startsWith('http');
                if (isExternal){
                    html += '<a class="btn tickets" target="_blank" href="'+t.right_btn_link+'">'+t.right_btn_title+teaserEl.externalLinkSvg+'</a>\n';
                } else {
                    html += '<a class="btn tickets" href="'+t.right_btn_link+'">'+t.right_btn_title+'</a>\n';
                }
            } else if (typeof t.detailLink !== 'undefined' && t.detailLink.length > 0){
                if (t.ticketlink.match("^http")){
                    html += '<a class="btn tickets" target="_blank" title="Öffnet in neuem Fenster: '+t.ticketlinkTitle+'" href="'+t.ticketlink+'">'+t.ticketlinkTitle+teaserEl.externalLinkSvg+'</a>\n';
                } else if (typeof t.ticketlink !== 'undefined' && t.ticketlink.length > 0){
                    html += '<a class="btn tickets" href="'+t.ticketlink+'">'+t.ticketlinkTitle+'</a>\n';
                } else if (typeof t.ticketlinkTitle !== 'undefined' && t.ticketlinkTitle.length > 0){
                    html += '<span class="btn tickets">'+t.ticketlinkTitle+'</span>\n';
                }
            }

            hasImg = t.images != null && t.images.length > 0;
            hasFilm = t.film != null && t.film.length > 0;
        }




        $(teaserEl.el).parent().html(html);

        if (hasFilm) {
            $('.bg-slider-container.film').remove();
            $('#bgmovie-sound-btns').show();

            let videoHtml = '<div class="bg-slider-container film show-for-medium-up">\n' +
                '                <video id="background_video" autoplay muted loop>\n' +
                '                    <source src="'+t.film+'" type="video/mp4">\n' +
                '                </div>\n' +
                '            </video>\n';

            $('.bg-slider-container.images').before(videoHtml);
            $('.bg-slider-container.images').addClass('has-film');

            if (!hasImg) {
                utils.fullscreenSlider();
            }

        }

        if (hasImg) {
            if (!hasFilm) {
                $('.bg-slider-container.film').remove();
                $('#bgmovie-sound-btns').hide();
                $('.bg-slider-container.images.has-film').removeClass('has-film');
            }

            let duration = $('.bg-slider-container.images').find('.bg-slider').attr('data-duration');

            let imgHtml = '<div data-duration="'+duration+'" class="bg-slider">';

            if (hasImg){
                for (let i = 0; i < t.images.length; i++) {
                    imgHtml += '<img src="'+t.images[i]+'"/>\n';
                }
                imgHtml += '</div>';
            }

            $('.bg-slider-container.images').find('.bg-slider.vegas-container').vegas('destroy').remove();
            if (t.images.length > 1){
                $('.bg-slider-container.images').find('.bg-slider').html(imgHtml);
            } else {
                $('.bg-slider-container.images').html(imgHtml);
            }
            utils.fullscreenSlider();
        }
    }
}
export default Teaser;
