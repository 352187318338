import Player from "./player";

const utils = require('../utils');
import $ from "jquery";

class Abonnement {

    constructor(el) {
        this.el = el;
        this.id = el.id.replace("abo_", "");
        this.settings = document.getElementById("abo_settings_"+this.id);

        // this.options = this.settings.dataset.options;
        this.options = $(this.settings).data('options');
        // this.concerts = this.settings.dataset.concerts;
        this.concerts = $(this.settings).data('concerts');
        this.plusAboConcerts = $(this.settings).data('plusaboConcerts');
        this.selectAmount = this.settings.dataset.selectAmount;
        this.aboPrefix = this.settings.dataset.aboPrefix;
        this.aboTitle = this.settings.dataset.title;
        this.plusAboTitle = this.settings.dataset.plusAboTitle;
        this.amountLabel = this.settings.dataset.amountLabel;
        this.seatwishLabel = this.settings.dataset.seatwishLabel;
        this.totalCHFLabel = this.settings.dataset.totalchfLabel;

        this.catSelection = $("input[name='cat-"+this.id+"']");
        this.concertOptions = document.getElementsByClassName("concertOptions")[0];
        this.plusAboOptions = document.getElementsByClassName("plusAboOptions")[0];
        this.categoriesOption = document.getElementsByClassName("categoriesOption")[0];
        this.seatOptions = document.getElementsByClassName("seatOptions")[0];
        this.chooseSeat = document.getElementsByClassName("chooseSeat")[0];
        this.toOrderForm = document.getElementsByClassName("toOrderForm")[0];

        this.catByFloorPlan = [["cat-1", "cat-2", "cat-3", "cat-4", "cat-5", "cat-6"], ["cat-1", "cat-2", "cat-3", "cat-6"]];

        this.init();
    }

    init(){
        let aboEl = this;
        let catSelection = $("input[name='cat-"+aboEl.id+"']");
        if (this.selectAmount>0){
            $(this.chooseSeat).fadeTo(0,0.3).prop("disabled",true);
        }
        $(this.toOrderForm).fadeTo(0,0.3).prop("disabled",true);
        $(this.concertOptions).find("input[type=checkbox]").attr("checked",false);
        this.resetSeatOptions();

        $(this.concertOptions).find(".concertOption").click(function (e) {
            if (!e.target.classList.contains('concert-title') && !e.target.classList.contains('arrow-down')){
                let checkbox = $(this).find("input[type=checkbox]");
                if ($(checkbox).is(':checked')){
                    $(checkbox).prop('checked', false);
                } else {
                    $(checkbox).prop('checked', true);
                }
                $(checkbox).trigger("change");
            }
        });

        $(".concert-title").click(function(){
            let t = $(this);
            t.parent().find(".description").slideToggle();
        });
        $(".close-concert").click(function(){
            $(this).parent().parent().find(".description").slideToggle();
        });
        let bigFloorPlan = aboEl.el.getElementsByClassName("big-floor-plan")[0];
        $(this.concertOptions).find("input[type=checkbox]").change(function(){
            let chooseCount = $(aboEl.concertOptions).find("input[type=checkbox]:checked").length;
            console.log("chooseCount")
            console.log(chooseCount)
            console.log(aboEl.selectAmount)
            if(chooseCount == aboEl.selectAmount){
                console.log("chooseCount === aboEl.selectAmount")
                $(aboEl.concertOptions).find("input[type=checkbox]:not(:checked)").prop("disabled",true);
                $(aboEl.chooseSeat).fadeTo(100,1).prop("disabled",false);
            }else{
                $(aboEl.concertOptions).find("input[type=checkbox]").prop("disabled",false);
                $(aboEl.chooseSeat).fadeTo(100,0.3).prop("disabled",true);
                aboEl.resetSeatOptions();
            }
        });
        let activeFloorClass = "";
        if (bigFloorPlan){
            $(this.categoriesOption).find("label").mouseover(function () {
                activeFloorClass = this.id;
                bigFloorPlan.classList.add(activeFloorClass, "active");
            });
            $(this.categoriesOption).find("label").mouseleave(function () {
                bigFloorPlan.classList.remove(activeFloorClass, "active");
                activeFloorClass = "";
            });
            utils.each(bigFloorPlan.getElementsByClassName('cat-item'), svgCatItem => {
                let id = svgCatItem.classList[1];
                let checkbox = document.getElementById(id);
                svgCatItem.addEventListener("click", e => {
                    checkbox.click();
                });
            });
        }

        let selectedCat = "";
        let plusAboAdditionalPrice = 0;
        let activeCatClass = "";
        let seatCatOptions = aboEl.el.getElementsByClassName("seatCatOptions")[0];
        let catSelectedOptionResume = aboEl.el.getElementsByClassName("cat-selected-option-resume")[0];
        let selectedCatColor = catSelectedOptionResume.getElementsByClassName("selected-cat-color")[0];
        let selectedCatTitle = catSelectedOptionResume.getElementsByClassName("selected-cat-title")[0];
        let selectedCatPrice = catSelectedOptionResume.getElementsByClassName("selected-cat-price")[0];
        let seatCount = aboEl.el.getElementsByClassName("seat-count")[0];
        let aboResult = aboEl.el.getElementsByClassName("abo-result")[0];
        $(this.categoriesOption).find("input[type=radio]").change(function(){
            let catCount = $(aboEl.categoriesOption).find("input[type=radio]:checked").length;
            if  (catCount > 0){
                $(seatCatOptions).slideDown(100);
            }
            if (this.checked == true){
                let label = this.parentElement;
                if (activeCatClass){
                    bigFloorPlan.classList.remove(activeCatClass);
                }
                activeCatClass = "active-"+label.id;
                bigFloorPlan.classList.add(activeCatClass);
                selectedCatColor.style.backgroundColor = label.dataset.color;
                selectedCatTitle.innerHTML = label.dataset.title;
                selectedCatPrice.innerHTML = aboEl.getFloat(label.dataset.price).toFixed(2);
                selectedCat = label.id;
                updateAboResult();
            } else {
                bigFloorPlan.classList.remove(activeCatClass);
            }
        });

        launchPlusAboOptions();

        $('.seat-resume .minus').click(function () {
            let count = parseInt($(seatCount).val()) - 1;
            count = count < 1 ? 1 : count;
            $(seatCount).val(count);
            $(seatCount).change();
            updateAboResult();
            return false;
        });
        $('.seat-resume .plus').click(function () {
            $(seatCount).val(parseInt($(seatCount).val()) + 1);
            $(seatCount).change();
            updateAboResult();
            return false;
        });

        function launchPlusAboOptions() {
            let plusAboOptions = aboEl.el.getElementsByClassName("plusAboOptions")[0];
            if (plusAboOptions) {
                // launch eventListener
                $('.plusAboOption').each(function( index, element ) {
                    let id = element.dataset.plusId;
                    let checkBox = document.getElementById('plus-ce-'+id)
                    checkBox.addEventListener('change', function() {
                        // todo: slide up / down
                        let newTotal = document.getElementById('plus-abo-new-total');
                        newTotal.classList.remove('hide');
                        // console.log("checkbox changed")
                        if (checkBox.checked){
                            plusAboAdditionalPrice += aboEl.getFloat(element.dataset.priceCat);
                            element.classList.add('active');
                        } else {
                            plusAboAdditionalPrice -= aboEl.getFloat(element.dataset.priceCat);
                            element.classList.remove('active');
                        }
                        updateNewTotal();
                    });
                });
            }
        }

        const updatePlusAbo = () => {
            // only update if concerts are selected
            $('.plusAboOption').each(function( index, element ) {
                let id = element.dataset.plusId;
                let checkBox = document.getElementById('plus-ce-'+id);
                updatePlusAboPrice(element, id, checkBox);
            });
        }

        const updatePlusAboPrice = (element, id, checkBox) => {
            let plusAboPriceOriginal = document.getElementById('plus_abo_price_original-'+id);
            let plusAboPriceReduced = document.getElementById('plus_abo_price_reduced-'+id);
            let subTotal = document.getElementById('sub_total_abo_price_'+id);

            let selectedPriceCat;
            let selectedCatStatus;
            switch (selectedCat) {
                case 'cat-1':
                    selectedPriceCat = element.dataset.priceCat1;
                    selectedCatStatus = element.dataset.catStatus1;
                    break;
                case 'cat-2':
                    selectedPriceCat = element.dataset.priceCat2;
                    selectedCatStatus = element.dataset.catStatus2;
                    break;
                case 'cat-3':
                    selectedPriceCat = element.dataset.priceCat3;
                    selectedCatStatus = element.dataset.catStatus3;
                    break;
                case 'cat-4':
                    selectedPriceCat = element.dataset.priceCat4;
                    selectedCatStatus = element.dataset.catStatus4;
                    break;
                case 'cat-5':
                    selectedPriceCat = element.dataset.priceCat5;
                    selectedCatStatus = element.dataset.catStatus5;
                    break;
                case 'cat-6':
                    selectedPriceCat = element.dataset.priceCat6;
                    selectedCatStatus = element.dataset.catStatus6;
                    break;
            }
            let tmp = aboEl.getFloat(selectedPriceCat);
            plusAboPriceOriginal.innerHTML = "CHF " + selectedPriceCat;
            plusAboPriceReduced.innerHTML = "CHF " + (tmp / 2).toFixed(2);
            subTotal.innerHTML = "CHF " + (tmp / 2).toFixed(2);
            element.dataset.priceCat = tmp / 2 +"";
            if (checkBox.checked && selectedCatStatus !== "true"){
                plusAboAdditionalPrice += tmp / 2;
            }
            if (selectedCatStatus === "true"){
                element.classList.add("sold-out");
                checkBox.disabled = true;
                if (checkBox.checked) {
                    element.classList.remove('active');
                }
            } else {
                element.classList.remove("sold-out");
                checkBox.disabled = false;
                if (checkBox.checked && !element.classList.contains('active')) {
                    element.classList.add('active');
                }
            }
        }

        const updateNewTotal = () => {
            let newTotal = document.getElementById('new-total');
            let tmp = aboEl.getFloat(selectedCatPrice.innerHTML);
            if (newTotal){
                newTotal.innerHTML = (tmp + plusAboAdditionalPrice).toFixed(2) + "";
            }
            aboResult.innerHTML = ($(seatCount).val() * (tmp + plusAboAdditionalPrice)).toFixed(2);
        }


        function updateAboResult(){
            let tmp = aboEl.getFloat(selectedCatPrice.innerHTML);
            // console.log("category change, new price: " + $(seatCount).val() * tmp)
            plusAboAdditionalPrice = 0;
            updatePlusAbo();
            updateNewTotal();
            aboResult.innerHTML = ($(seatCount).val() * (tmp + plusAboAdditionalPrice)).toFixed(2);
        }

        $(this.chooseSeat).click(function(){
            $(aboEl.seatOptions).slideDown(100);
        });
        catSelection.change(function () {
            $(aboEl.toOrderForm).fadeTo(100,1).prop("disabled",false);
        });

        let orderform = $("#order-form-"+aboEl.id);
        $(orderform).submit(function(e){
            let cat = $("input[name='cat-"+aboEl.id+"']:checked").val() - 1;
            let amount = $("#abo-amount-"+aboEl.id).val();

            let title = "<strong>" + aboEl.aboPrefix + aboEl.aboTitle +"</strong><br/>";
            let selectedConcertsDisplay = aboEl.getSelectedConcerts(aboEl.concertOptions, aboEl.concerts, aboEl.selectAmount)+"<br/>";
            let plusAboTitle = aboEl.plusAboTitle +"<br/>";
            let plusAboSelectedConcertsDisplay = "";
            if ($(aboEl.plusAboConcerts).length > 0){
                plusAboSelectedConcertsDisplay = aboEl.getSelectedConcerts(aboEl.plusAboOptions, aboEl.plusAboConcerts, 1);
                if (plusAboSelectedConcertsDisplay != ""){plusAboSelectedConcertsDisplay = "<strong>"+plusAboTitle+"</strong>"+plusAboSelectedConcertsDisplay+"<br/>";}
            }
            let kategorieDisplay = "<strong>"+$(aboEl.el).find('.floor-plan-name').html()+" "+aboEl.options[cat].name+"</strong><br/>";
            let amountDisplay = "<strong>"+aboEl.amountLabel+": "+amount+"</strong><br/>";

            let seatWishDisplay = "<strong>"+aboEl.seatwishLabel+"</strong><br/>";
            for(let i=1;i<4;i++){
                let id = "seat-wish-"+aboEl.id+"-"+i;
                let sw = $("#"+id);
                if(sw.length>0){
                    let label = $("label[for='"+id+"']").text().trim();
                    if(label.length>0){seatWishDisplay += label+": ";}
                    seatWishDisplay += sw.val().replace(/\n/g,"##br##").replace(/<[^>]*>?/gm, '').replace("##br##", "<br/>")+"<br/>";
                }
            }

            // let priceDisplay = "<hr><strong>"+aboEl.totalCHFLabel+(aboEl.options[cat].price * amount)+".00</strong><hr>";
            // todo: check if i can replace aboEl.options[cat].price like this
            let priceDisplay = "<hr><strong>"+aboEl.totalCHFLabel+(parseFloat(aboResult.innerHTML.replace(",",".")).toFixed(2))+"</strong><hr>";

            let text = "<p>"+title+selectedConcertsDisplay+plusAboSelectedConcertsDisplay+kategorieDisplay+amountDisplay+seatWishDisplay+priceDisplay+"</p>";
            $(orderform).find("input[name='abo-details']").val(text);
        });

        $(aboEl.el.getElementsByClassName('seatOption')).click(e => {
            let target = e.currentTarget;
            if (!target.classList.contains('active')){
                $(target.parentElement).find('.active').removeClass('active');

                let planTargetNb = target.dataset.planNumber;
                let planTitle = target.dataset.planTitle;
                $(aboEl.el).find('.floor-plan.active').removeClass('active');
                $(aboEl.el).find('.big-floor-plan-'+ planTargetNb).addClass('active');
                $(aboEl.el).find('.floor-plan-name').html(planTitle);
                target.classList.add('active');
                $(aboEl.categoriesOption).find("input[type=radio]:checked").prop('checked', false);
                if (activeCatClass){
                    bigFloorPlan.classList.remove(activeCatClass);
                }
                $(aboEl.categoriesOption).find("input[type=radio]").each(function( index, element ) {
                    let catNb = element.value;
                    if (aboEl.catByFloorPlan[planTargetNb-1].includes("cat-"+element.value)){
                        element.parentElement.classList.remove("disabled");
                        element.disabled = false;
                    } else {
                        element.parentElement.classList.add("disabled");
                        element.disabled = true;
                    }
                });
            }
        });
    }

    resetSeatOptions(){
        this.catSelection.prop("checked",false);
        $(this.seatOptions).slideUp(100);
        $(this.toOrderForm).fadeTo(0,0.3).prop("disabled",true);
    }

    getSelectedConcerts(concertOptions,concerts, limit){
        let selectedConcerts = "";
        console.log(limit);
        if (limit>0){
            $(concertOptions).find("input[type=checkbox]:checked").each(function(){
                let t = $(this);
                selectedConcerts += concerts[t.attr("id")]+"<br/>";
            });
        } else {
            $.each(concerts,function(i, value){
                selectedConcerts += value+"<br/>";
            });
        }
        return selectedConcerts;
    }

    getFloat(string){
        return parseFloat(string.replace(",","."));
    }
}
export default Abonnement;
