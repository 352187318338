import {lazyHandler} from "./lazy/lazyHandler";

const utils = require('./utils');
const navigation = require('./navigation');
import Fundraising from './components/fundraising';
import Shop from './components/shop';
import Abonnement from './components/abonnement';
import SoundCloud from './components/soundcloud';
import Concert from './components/concert';
import Teaser from './components/teaser';
import Player from './components/player';
import '@babel/polyfill';
import $ from 'jquery';
import 'jquery.cookie';
import 'foundation-sites';
import '@fancyapps/fancybox';
import 'vegas';
import 'jquery-parallax.js';
import powerTip from 'jquery-powertip';
import Rellax from 'rellax'

// Scroll offsets and positions
let wrapOffset;
let mainScroll;
let siteTitleScroll;
let siteTitleScrollFix;
let contentMarginTop;
let contentRightScroll;
let contentLeftScroll;
let mobile;
let hasFullsizeHeader;
let navHeight;

let lastContentHeight;

const sukoaProject = {
    init() {
        // tmitUtils.openExternalLinksInNewWindow();
        document.getElementsByTagName('html')[0].classList.remove('no-js');

        sukoaProject.mouseoverBoxInteraction();
        navigation.init();
        sukoaProject.initLazy();
        sukoaProject.initComponents();
        sukoaProject.initInlineLinkInteraction();
        sukoaProject.initFancyBox();
        sukoaProject.initDownCounter();
        sukoaProject.cookiesAlert();
        sukoaProject.dailyUpdate();
        sukoaProject.rellaxInit();

        let win = $(window);
        let body = $("body");

        mobile = utils.isMobile();
        hasFullsizeHeader = utils.hasFullsizeHeader();
        navHeight = utils.getNavHeight();

        if(utils.detectIE()){
            body.addClass("internet-explorer");
        }

        $('.tooltip').powerTip();

        $(window).resize(function() {
            utils.updateScrollPositions();
            sukoaProject.positionWrapperToConcertDetail();
        });

        $(window).scroll(function() {
            utils.updateScrollStyles();
        });

        setTimeout(function () {
            utils.updateScrollPositions();
            utils.updateScrollStyles();
            sukoaProject.jumpHeaderImage();
            sukoaProject.positionWrapperToConcertDetail();

            sukoaProject.initEventListeners(wrapOffset);
            utils.fullscreenSlider();
            utils.imageSlider();
            lastContentHeight = $('.content-left').outerHeight();
            sukoaProject.checkForContentHeightChanges();
            setTimeout(sukoaProject.checkForContentHeightChanges(), 500);
        }, 10);
    },

    initLazy() {
        lazyHandler();
    },

    checkForContentHeightChanges() {
        let contentLeft = $('.content-left');
        if (contentLeft.outerHeight() != lastContentHeight) {
            lastContentHeight = contentLeft.outerHeight();
            utils.updateScrollPositions();
            // setTimeout(sukoaProject.checkForContentHeightChanges(), 500);
        }
    },

    initEventListeners(){
        let win = $(window);
        let body = $("body");

        let mainTrig = $('#main-trigger');
        let mainNav = $("#main-nav");
        let topbarNav = $("#site-title-row");
        let pageDown = $("#arrow_go_down");
        let footerToggle = $(".footer-toggle");
        let sideNav = $("#content-side-nav");
        let orchesterVisual = $(".orchester-visual");
        let orchesterTitle = orchesterVisual.find('h2');
        let orchesterA = orchesterVisual.find("a.orchester-section");

        $(".triggerText").on( "click", function(e) {
            e.preventDefault();
            $(this).next().hasClass("hide")? $(this).next().css("display","none").removeClass("hide").slideDown() : $(this).next().slideUp().addClass("hide");
        });

        // Navmenus hide/show
        mainTrig.on( "click", function(e) {
            e.preventDefault();

            function mainTrigSwitch(){
                const content = mainTrig.html();
                mainTrig.html( mainTrig.data("switch-content") );
                mainTrig.data("switch-content", content);
                const title = mainTrig.attr('title');
                mainTrig.attr('title', mainTrig.data("switch-title") );
                mainTrig.data("switch-title", title);
            }

            if(!mainTrig.data("prevent-click")){
                mainTrig.data("prevent-click", true);
                mainNav.find("li:has(ul)").addClass("has-ul-child");
                // if (utils.isMobile()){
                if (utils.isMobileOrMedium()){
                    mainNav.find(".has-ul-child > a:not(.has-after)").each(function () {
                        $(this).after('<span class="open-subnav">&#x25b6;&#xfe0e;</span>');
                        $(this).addClass('has-after');
                    });

                    mainNav.find(".has-ul-child > a.active").each(function () {
                        if(!$(this).parent().hasClass('active')){
                            $(this).parent().addClass('active');
                        }
                        if(!$(this).parent().hasClass('active-only-open')){
                            $(this).parent().addClass('active-only-open');
                        }

                        $(this).parent().find('> .open-subnav').html("&#9660;").addClass('open');

                    });

                    mainNav.find(".has-ul-child.active.active-only-open").each(function () {
                        $(this).find('ul').first().slideDown();
                    });

                }

                topbarNav.addClass("main-nav-active");
                mainTrig.addClass("close");
                mainNav.animate({"margin-left": '+=100%'},function() {
                    mainTrigSwitch();
                    mainNav.addClass("shown");
                });
            } else if(mainTrig.data("prevent-click")) {
                mainTrig.data("prevent-click", false);
                mainTrig.removeClass("close");

                topbarNav.removeClass("main-nav-active");
                mainNav.animate({"margin-left": '-=100%'},function() {
                    mainTrigSwitch();
                    mainNav.removeClass("shown");
                });
            }
        });

        orchesterA.hover(function() {
            let t = $(this);
            let title = t.attr("title");
            let href = t.attr("href");
            let sideNavA = sideNav.find('a[href="'+href+'"]');

            orchesterTitle.text(title);
            if(sideNavA.hasClass('active')){
                sideNavA.data('active', 'current');
            }
            sideNavA.addClass('active');
        },function(){ // on unhover
            let t = $(this);
            let title = t.attr("title");
            let href = t.attr("href");
            let sideNavA = sideNav.find('a[href="'+href+'"]');

            orchesterTitle.text(orchesterTitle.data("title"));
            if(sideNavA.data('active') != 'current'){
                sideNavA.removeClass('active');
            }
        });

        if(orchesterVisual.length){
            sideNav.find('li>a').hover(function() {
                let t = $(this);
                let href = t.attr("href");
                let section = orchesterA.filter('a[href="'+href+'"]');
                let title = section.attr("title");

                orchesterTitle.text(title);
                section.attr('class','orchester-section active-section'); //cant use addClass in svg dom
            },function(){ // on unhover
                let t = $(this);
                let href = t.attr("href");
                let section = orchesterA.filter('a[href="'+href+'"]');
                let title = section.attr("title");

                orchesterTitle.text(orchesterTitle.data("title"));
                section.attr('class','orchester-section '+section.data('active'));
            });
        }

        body.on('click','.show-more-news',function(){
            const newsArea = $(this).closest(".news-area");
            let moreentries = newsArea.find(".news-entry-wrapper:hidden");
            if(moreentries.length < 4){
                $('.show-more-news').hide();
            }
            moreentries.slice(0,3).slideDown();
        });
        body.on('click','.show-more-highlights',function(){
            const highlightsSlider = $(this).siblings(".highlights-slider");
            let moreentries = highlightsSlider.find(".highlights-entry-wrapper:hidden");
            console.log(moreentries)
            console.log(moreentries.length)
            if(moreentries.length < 3){
                $('.show-more-highlights').removeClass('show-on-mobile');
            }
            moreentries.slice(0,2).slideDown();
        });
        body.on('click','.highlights-controls:not(.disabled)',function(){
            const btn = $(this);
            const btns = $('.highlights-controls');
            btns.addClass('disabled');

            const goesLeft = btn.hasClass("highlights-arrow-left");
            const highlightsSlider = btn.siblings(".highlights-slider");

            //get Highlights
            const currentHighlights = highlightsSlider.find(".highlights-entry-wrapper:visible");
            let allNext = currentHighlights.nextAll(".highlights-entry-wrapper:hidden");
            let nextHighlights = allNext.slice(0,2);
            if (goesLeft) {
                allNext = currentHighlights.prevAll(".highlights-entry-wrapper:hidden");
                nextHighlights = $(allNext.slice(0, 2).get().reverse());
            }
            let nextHighlightRight = nextHighlights.slice(1,2);

            //position next ones to left or right
            nextHighlights.css("transform",`translateX(${goesLeft ? -100 : 100}vw)`);
            nextHighlights.css("display","block");
            nextHighlights.css("position","absolute");
            nextHighlightRight.css("right","0");
            setTimeout(function () {
                //kick off animation
                nextHighlights.add(currentHighlights).css("transition","transform 0.5s");
                nextHighlights.css("transform","translateX(0)");
                currentHighlights.css("transform",`translateX(${goesLeft ? 100 : -100}vw)`);
            }, 10);
            setTimeout(function () {
                //animation done, cleanup
                nextHighlights.add(currentHighlights).css({"transition":"", "transform":""});
                currentHighlights.css("display","none");
                nextHighlights.css("position","");
                nextHighlightRight.css("right","");
                btns.removeClass('disabled');

                //show/hide controls
                let noMoreLeft = (nextHighlights.prevAll(".highlights-entry-wrapper:hidden").length === 0);
                let noMoreRight = (nextHighlights.nextAll(".highlights-entry-wrapper:hidden").length === 0);
                noMoreLeft ? btns.filter(".highlights-arrow-left").hide() : btns.filter(".highlights-arrow-left").show();
                noMoreRight ? btns.filter(".highlights-arrow-right").hide() : btns.filter(".highlights-arrow-right").show();
            }, 510);

        });


        body.on( "click", "a.show-calendar-mobile", function(e) {
            e.preventDefault();

            $(".show-filter-mobile").removeClass("active");
            $(".filter-display").removeClass("show-on-mobile");
            $(this).toggleClass("active");
            $(".calendar-display").toggleClass("show-on-mobile");
        });
        body.on( "click", "a.show-filter-mobile", function(e) {
            e.preventDefault();

            $(".show-calendar-mobile").removeClass("active");
            $(".calendar-display").removeClass("show-on-mobile");
            $(this).toggleClass("active");
            $(".filter-display").toggleClass("show-on-mobile");
        });

        footerToggle.on( "click", function(e) {
            e.preventDefault();
            $(this).parent().toggleClass("active");
            $(this).siblings(".hidden").slideToggle();
        });

        $("#arrow_go_up, #arrow_go_down, a[href='#footer']").click(function(e){
            e.preventDefault();
            let targetID = this.getAttribute("href").replace('#','');
            console.log(targetID)
            let targetEl = document.getElementById(targetID);
            // const navHeight = document.getElementById('site-title-row').getBoundingClientRect().height;

            let offset = targetEl.offsetTop - navHeight;

            if (targetID === 'footer') {
                if (hasFullsizeHeader){
                    if ($('body').hasClass('is-detail')){
                        offset = targetEl.offsetTop - navHeight + $('#concert-details').outerHeight(true);
                    } else {
                        offset = targetEl.offsetTop - navHeight + window.innerHeight;
                    }
                } else {
                    offset = targetEl.offsetTop + (((window.innerHeight - navHeight) / 3) * 2);
                }
            }

            if (targetEl){
                $("body,html").animate({
                    scrollTop: offset
                }, 600);
            }
        });
        $(".concert-format-trigger, #concert-format-close").click(function(e){
            e.preventDefault();
            $(".concert-format-trigger").toggleClass("active");
            $("#concert-format").slideToggle();
        });

        sukoaProject.newsletterInteraction();
    },

    initDownCounter() {
        let counter = document.getElementById("date_downcounter");
        if (counter){
            let counterDate = counter.dataset.downcounterDate;
            let second = 1000,
                minute = second * 60,
                hour = minute * 60,
                day = hour * 24;

            let countDown = new Date(counterDate).getTime(),
                x = setInterval(function() {

                    let now = new Date().getTime(),
                        distance = countDown - now;

                    document.getElementById('days').innerText = Math.floor(distance / (day)),
                        document.getElementById('hours').innerText = Math.floor((distance % (day)) / (hour)),
                        document.getElementById('minutes').innerText = Math.floor((distance % (hour)) / (minute)),
                        document.getElementById('seconds').innerText = Math.floor((distance % (minute)) / second);

                    //do something later when date is reached
                    if (distance <= 0) {
                        counter.style.visibility = "hidden";
                        clearInterval(x);
                    }

                }, second)
        }
    },

    initFancyBox(){
        $('.fancybox').fancybox({
            helpers: {
                overlay: {
                    locked: false
                }
            }
        });
    },

    initInlineLinkInteraction(){

        $("#content-main-wrap").on("click",".showinline",function(e){
            let t = $(this);
            let h = t.attr("href");
            let showinlinecontainer = t.parent().find(".showinlinecontainer");
            if(!showinlinecontainer.length>0){
                t.parent().append("<div class=\"hide row\"><div class=\"column\"><div class=\"showinlinecontainer\"></div></div></div>");
                showinlinecontainer = t.parent().find(".showinlinecontainer");
            }
            let wrapper = showinlinecontainer.parent().parent();

            if(showinlinecontainer.length>0){
                e.preventDefault();
                if(!t.hasClass("open")){
                    if (h != "#"){
                        $.ajax({
                            url:h,
                            success: function(response) {
                                let c = $(response).find("#content-main-wrap");
                                if(c.length>0){
                                    showinlinecontainer.html(c.html());
                                    wrapper.hide().removeClass("hide").delay(5).slideDown();
                                }
                            }
                        });
                    } else {
                        wrapper.hide().removeClass("hide").delay(5).slideDown();
                    }
                }else{
                    showinlinecontainer.parent().parent().slideUp(200,function(){
                        if (h != "#") {
                            showinlinecontainer.html("");
                        }
                    });
                }
                t.toggleClass("open");
            }

        }).on("click",".closeinlinecontainer",function(){
            $(this).parent().parent().parent().find(".showinline").click();
        });
    },

    initComponents() {
        new Shop(document.getElementsByClassName("basket"));
        new SoundCloud(document.getElementsByClassName("soundcloud-player"));

        utils.each(document.getElementsByClassName('fundraising-component'), fundraisingComponent => new Fundraising(fundraisingComponent).init());
        utils.each(document.getElementsByClassName('form-fundraising-cart'), fundraisingComponent => new Fundraising(fundraisingComponent).updateFundraisingCart());
        utils.each(document.getElementsByClassName('abonnement-component'), abonnementComponent => new Abonnement(abonnementComponent));

        utils.each(document.getElementsByClassName('concert-list-component'), concertListComponent => new Concert(concertListComponent));
        utils.each(document.getElementsByClassName('teaser-component'), teaserComponent => new Teaser(teaserComponent));
        utils.each(document.getElementsByClassName('player-component'), playerComponent => new Player(playerComponent));


        utils.each(document.getElementsByClassName('program-entry'), programComponentEntry => {
            let id = programComponentEntry.id;
            utils.each(programComponentEntry.getElementsByClassName("program-show-detail"), showDetailElt => {
                showDetailElt.addEventListener('click', e => {
                    e.preventDefault();
                    programComponentEntry.classList.add('show-detail');
                });
            });
            utils.each(programComponentEntry.getElementsByClassName("program-hide-detail"), hideDetailElt => {
                hideDetailElt.addEventListener('click', e => {
                    e.preventDefault();
                    programComponentEntry.classList.remove('show-detail');
                });
            });
        });

        sukoaProject.conditionalShowField();
    },

    cookiesAlert() {

        let cookieName = 'sinfonieorchesterbasel-cookie-accept';
        let cookieAlertEl = document.getElementById('cookieAlert');
        let arrowGoDown = document.getElementById('arrow_go_down_wrap');
        if (cookieAlertEl) {
        let cookieAlertHeight = cookieAlertEl.offsetHeight;

            if (document.cookie.indexOf(cookieName) === -1) {
                cookieAlertEl.classList.remove('accepted');
                updateCookieAlertHeight();

                setTimeout(function () {
                    updateCookieAlertHeight();
                }, 100);

                window.addEventListener("scroll", function(event) {
                    let top = this.scrollY;
                    if (top <= cookieAlertHeight){
                        arrowGoDown.style.bottom=(cookieAlertHeight - top)+"px";
                    } else{
                        arrowGoDown.style.bottom="0";
                    }
                }, false);

                window.onresize = function(){
                    updateCookieAlertHeight();
                };
            }

            let cookieBtn = document.getElementById("cookieConsent");

            cookieBtn.addEventListener('click', evt => {
                let d = new Date();
                d.setTime(d.getTime() + (360 * 24 * 60 * 60 * 1000));
                document.cookie = cookieName + "=;expires=" + d.toUTCString() + ";path=/";
                cookieAlertEl.classList.add('accepted');
                $(cookieAlertEl).hide(300, function () {
                    updateCookieAlertHeight();
                });
            });

            function updateCookieAlertHeight(){
                cookieAlertHeight = cookieAlertEl.offsetHeight;
                arrowGoDown.style.bottom = cookieAlertHeight + "px";
                document.getElementById('wrapper').style.paddingBottom = cookieAlertHeight + "px";
            }

        }
    },

    dailyUpdate() {

        let dailyUpdateName = 'sinfonieorchesterbasel-daily-update-accept';
        let dailyUpdateEl = document.getElementById('daily_update');
        if (dailyUpdateEl) {
            let lastModificationDate = dailyUpdateEl.dataset.lastModification;
            lastModificationDate = new Date(lastModificationDate).getTime();

            if (document.cookie.indexOf(dailyUpdateName) === -1) {
                dailyUpdateEl.classList.remove('accepted');
            } else {
                if(lastModificationDate > utils.getCookie(dailyUpdateName)){
                    dailyUpdateEl.classList.remove('accepted');
                }
            }

            let cookieBtn = document.getElementById("close_daily_update");

            cookieBtn.addEventListener('click', evt => {
                let d = new Date();
                d.setTime(d.getTime() + (1 * 1 * 60 * 60 * 1000));
                document.cookie = dailyUpdateName + "="+Date.now()+";expires=" + d.toUTCString() + ";path=/";

                dailyUpdateEl.classList.add('accepted');
            });
        }
    },

    jumpHeaderImage(){
        let body = $("body");
        // const navHeight = document.getElementById('site-title-row').getBoundingClientRect().height;
        // scroll/jump page down on subpage
        if(utils.getParameterByName("skiphead") && utils.getParameterByName("t") > 0){
            $("body,html").css("opacity", 0);
            $("body,html").scrollTop(utils.getParameterByName("t") - navHeight);
            setTimeout(function () {
                $("body,html").scrollTop(utils.getParameterByName("t") - navHeight);
                $("body,html").css("opacity", 1);
                utils.updateScrollPositions();
                utils.updateScrollStyles();
            }, 200);
            setTimeout(function () {
                $("body,html").scrollTop(utils.getParameterByName("t") - navHeight);
                $("body,html").css("opacity", 1);
                utils.updateScrollPositions();
                utils.updateScrollStyles();
            }, 400);
        } else if(body.hasClass("skiphead") || utils.getParameterByName("skiphead")){
            $("body,html").scrollTop(utils.getWrapOffset() - navHeight);
        }
    },

    conditionalShowField(){
        let conditionalShowFields = document.getElementsByClassName("form-element conditionalShow");
        Array.prototype.filter.call(conditionalShowFields, function(conditionalShowField){
            let fields = conditionalShowField.dataset.conditionalFields;
            let fieldName = conditionalShowField.dataset.fieldName;
            let myFields = $(''+fields+'');
            myFields.slideUp(1);
            let me = $('#'+fieldName+'_check');
            let mail = $('#'+fieldName);
            me.change(function(){
                if(me.attr("checked")=="checked"){
                    mail.attr("name","${fieldName}");
                }else{
                    mail.attr("name","${fieldName}_off");
                }
                myFields.toggle();
            });
        });
    },

    mouseoverBoxInteraction() {
        $(".mouseoverBox .posterframe").mouseenter(function(){
            let box = $(this).parent();
            let cw = box.find(".mouseoverContentWrapper");
            let ct = cw.find(".mouseoverContent");

            //first position - need to switch hiding method to get height
            cw.css('visibility','hidden').css('display','block');
            let m = (box.height()-ct.height())/2;
            if(m<10){m=10;}
            ct.css("margin-top",m+"px");
            cw.css('display','none').css('visibility','visible');

            //then fade in
            cw.css('visibility','visible').hide().fadeIn();
        });
        $(".mouseoverBox .mouseoverContentWrapper").mouseleave(function(){
            $(this).fadeOut();
        });
    },

    newsletterInteraction(){
        let body = $("body");

        body.on( "click", ".newsletter-trigger", function(e) {
            e.preventDefault();
            $(this).toggleClass("active");
            let form = $(this).siblings(".newsletter-form");
            form.slideToggle();
        });
        body.on("submit",".newsletter-form",function(e){
            let form = $(this);
            form.find(".error").removeClass("error");
            e.preventDefault();
            let name  = form.find(".newsletter-form-name");
            let email  = form.find(".newsletter-form-email");
            let cancel  = form.find(".newsletter-form-cancel");
            let loading  = form.parent().find(".newsletter-loading");
            let subscribed  = form.parent().find(".newsletter-subscribed");
            let unsubscribed  = form.parent().find(".newsletter-unsubscribed");
            let failed  = form.parent().find(".newsletter-failed");
            let cancel_val = cancel.is(":checked");

            let email_val = email.val();
            if(email_val!=""){
                loading.removeClass("hide").hide().slideDown(200);
                form.slideUp(200,function(){
                    $.ajax({
                        url: utils.getCurrentURL()+"?newlsetterSubscribe=true&ck="+new Date().getTime(),
                        type: 'post',
                        data: form.serialize(),
                        async: false,
                        success: function(data) {
                            sukoaProject.newsletterFinishedProcess(name, email, form, loading);
                            if(data.trim().indexOf("success")==0){
                                if(cancel_val){
                                    unsubscribed.removeClass("hide");
                                }else{
                                    subscribed.removeClass("hide");
                                }
                            }else{
                                failed.removeClass("hide");
                            }
                        },
                        error: function(){
                            sukoaProject.newsletterFinishedProcess(name, email, form, loading);
                            failed.removeClass("hide");
                        }
                    });
                });
            }else{
                email.addClass("error");
            }


        });
    },
    newsletterFinishedProcess(name, email, form, loading){
        name.val("");
        email.val("");
        form.hide();
        loading.hide();
        $.get(utils.getCurrentURL()+"?newlsetterSubscribe=end&ck="+new Date().getTime());
        // window.setTimeout(function(){
        //     $(".newsletter-loading,.newsletter-subscribed,.newsletter-unsubscribed,.newsletter-failed").slideUp();
        // },3000);
    },


    positionWrapperToConcertDetail(){
        let concertDetails = $("#concert-details");
        let wrapper = $("#wrapper");
        if(concertDetails.length>0 && wrapper.length>0){
            let oh = Math.max(concertDetails.outerHeight(),$(window).outerHeight());
            wrapper.css("top",oh+"px");
        }
    },

    rellaxInit() {
        let container = document.querySelector('.startpage-teaser-parallax');

        if (container) {
            window.onresize = resize;
            let idt;
            var rellax = null;

            function resize() {
                if (idt) clearTimeout(idt);
                idt = setTimeout(initRellax, 100);
            }

            function initRellax() {
                let parallaxContainers = document.querySelectorAll('.parallax-container');
                if(utils.isMobile()) {
                    if(rellax) rellax.destroy();
                    // parallaxContainers.forEach(pc => pc.classList.add('parallax-deactivated'));
                    for (let i = 0; i<parallaxContainers.length; i++) {
                        parallaxContainers[i].classList.add('parallax-deactivated');
                    }

                } else {
                    if(rellax && rellax.refresh) {
                        rellax.refresh();
                    } else {
                        rellax = new Rellax('.rellax',  {
                            center: true,
                            wrapper: null,
                            round: true,
                            vertical: true,
                            horizontal: false
                        });
                    }
                    // parallaxContainers.forEach(pc => pc.classList.remove('parallax-deactivated'));
                    for (let i = 0; i<parallaxContainers.length; i++) {
                        parallaxContainers[i].classList.remove('parallax-deactivated');
                    }
                }
            }
            initRellax();
        }
    },
};

const validateFormUtils = {

    validateForm(form) {
        let result = true;
        let f = $(form);
        let mandatoryAlert = form.dataset.mandatoryAlert;
        let invalidEmailAlert = form.dataset.invalidEmailAlert;

        f.find('.error').removeClass('error');
        f.find(".emptybasketwarning").each(function(){
            alert($(this).text());
            result = false;
        });
        f.find(":text.required, input:file.required, input:radio.required, textarea.required").each(function () {
            if ($(this).val().trim() === "") {
                validateFormUtils.handleValidationError(this, mandatoryAlert );
                result = false;
            }
        });
        f.find("select.required").each(function () {
            if ($(this).prop("selectedIndex") === 0) {
                validateFormUtils.handleValidationError(this, mandatoryAlert );
                result = false;
            }
        });
        f.find(":text.email").each(function () {
            let c = $(this).val().trim();
            if (c !== "" && !validateFormUtils.validateEmail(c)) {
                validateFormUtils.handleValidationError(this, invalidEmailAlert );
                result = false;
            }
        });
        f.find('.optiongroup').each(function(){
            if($(this).parent().hasClass("required")){
                if ( $(this).find('input:radio,input:checkbox').length>0 && $(this).find('input:radio:checked,input:checkbox:checked,.custom.radio.checked').length==0  ) {
                    validateFormUtils.handleValidationError(this, mandatoryAlert );
                    result = false
                }
            }
        });
        if(result){
            $(".form-element:hidden").remove();
            if  (document.getElementById('fundraising_cart')){
                validateFormUtils.resetFundraisingCart('sob-fundraising-cart');
            }
        }
        return result;
    },

    handleValidationError(obj, message) {
        $(obj).addClass("error");
        if ( $('.error').length<=1) {
            alert(validateFormUtils.getJsDecodedString(message));
        }
        return false;
    },

    validateEmail(email) {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },

    getJsDecodedString(raw) {
        let div = document.createElement('div');
        div.innerHTML = raw;
        let decoded = div.firstChild.nodeValue;
        return decoded;
    },

    resetFundraisingCart(storageKey){
        localStorage.setItem(storageKey, JSON.stringify({lastUpdate: new Date(), cartEntries:[]}))
    }
};

window.validateFormUtils = {validateForm : validateFormUtils.validateForm};
window.sukoaUtils = {dcmadr : utils.dcmadr};
window.fundraisingCart = { resetFundraisingCart : validateFormUtils.resetFundraisingCart};

$(document).ready(function(){
    sukoaProject.init();
});



