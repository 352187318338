import $ from "jquery";
import "jquery-ui/ui/widget"

class SoundCloud{

    constructor(el) {
        this.el = el;
        this.init();
    }

    init() {
        let scEl = this;
        if (this.el.length > 0) {
            let body = document.body;
            $.getScript( "https://w.soundcloud.com/player/api.js", function() {
                let scIFrame = $('iframe.sc-widget');
                if (!scIFrame.length) {
                    $(body).append('<iframe class="sc-widget" src="//w.soundcloud.com/player/?url="></iframe>');
                    scIFrame = $('iframe.sc-widget');
                }

                scEl.widget = SC.Widget(scIFrame[0]);

                // $(body).on('click', 'a.show-songs', function(e) {
                //     e.preventDefault();
                //     let hiddenEl = $(this).siblings(".hidden");
                //     let scPlayer = hiddenEl.find(".soundcloud-player");
                //     scEl.createPlaylistHtml(scPlayer);
                //     hiddenEl.toggle(1000);
                //     scEl.widget.pause();
                // });
                //
                // $(body).on( 'click', '.song', function(e) {
                //     e.preventDefault();
                //     let song = $( this );
                //
                //     $(".song.active").removeClass("active");
                //     song.addClass("active");
                //     $('#player').remove();
                //     song.after('<div id="player"> <div class="play-toggle loading">&#9658;</div> <div class="circle c-left">&#9675;</div> <div class="progress"> <div class="scrubber"></div> </div> <div class="scrubline"></div> <div class="circle c-right">&#9675;</div> </div>');
                //
                //     let scPlayer = song.closest(".soundcloud-player");
                //     scEl.playSc(scPlayer, song.data("index"), true);
                // });

                $(scEl.el).filter(".show-onload").each(function(){
                    scEl.playSc($(this),0,false);
                });

            });
        }
    }

    playSc(scPlayer, index, autoPlay){
        let scEl = this;
        let url = scPlayer.data("url");
        let player = scPlayer.find('#player');
        let progress = player.find('.progress');
        let playToggle = player.find('.play-toggle');
        let scrubber = progress.find('.scrubber');
        let pOffset = progress.offset();
        let pWidth = progress.width();
        let bindScrubber = true;
        let scrub;
        let options = {};

        options.start_track = index;
        options.callback = function(){
            scEl.widget.bind(SC.Widget.Events.READY, function() {
                scEl.setInfo();
                playToggle.removeClass("loading");
                if(autoPlay){
                    scEl.widget.play();
                }
                else {
                    playToggle.toggleClass("playing");
                }
            }); //Set info on load

            scEl.widget.bind(SC.Widget.Events.PLAY_PROGRESS, function(e) {
                if( e.relativePosition < 0.003 ) { scEl.setInfo(); }
                if(bindScrubber) { scrubber.css('left', ( e.relativePosition*100)+"%"); }
            });

            progress.mousemove(function(e){ //Get position of mouse for scrubbing
                scrub = (e.pageX-pOffset.left);
            });

            progress.mousedown(function() { //Scrubber draggable
                $(document).mousemove(function(e) {
                    bindScrubber = false;
                    scrubber.offset({ left: e.pageX });
                }).mouseup(function () {
                    $(this).unbind("mousemove");
                    bindScrubber = true;
                });
            });

            progress.click(function(){ //Use the position to seek when clicked
                scrubber.css('left',scrub+"px");
                let seek = scEl.widget.duration*(scrub/pWidth);
                scEl.widget.seekTo(seek);
            });

            playToggle.click(function(){
                let ac = new AudioContext();
                $(this).toggleClass("playing");
                scEl.widget.toggle();
            });

        };
        scEl.widget.load(url, options);

    }

    setInfo() {
        let scEl = this;
        scEl.widget.getCurrentSound(function(song) {
            scEl.widget.current = song;
        });
        scEl.widget.getDuration(function(value){
            scEl.widget.duration = value;
        });
    }

    createPlaylistHtml(players) {
        let scEl = this;
        let scPlayer = $(players.get(0));
        players.splice(0,1);
        if(scPlayer.data("loadedPlaylist")) {
            if(players.length > 0) {
                scEl.createPlaylistHtml(players); //recall self till done
            }
        }
        else {
            let url = scPlayer.data("url");
            let songsEl = scPlayer.find('.songs');
            let options = {};

            options.callback = function(){
                scEl.widget.getSounds(function(songs) {
                    if(songs.length > 0) {
                        let songsLength = songs.length > 5? 5 : songs.length;
                        for (let i = 0; i < songsLength; ++i) {
                            let song = songs[i];
                            songsEl.append('<li class="song" data-index="'+i+'"><a href=""><span class="playtag">&#9658;</span>'+song.title+'</a></li>');
                        }
                    }
                    scPlayer.data("loadedPlaylist", true);
                    if(players.length > 0) {
                        scEl.createPlaylistHtml(players); //recall self till done
                    }
                });
            };
            scEl.widget.load(url, options);
        }
    }

}
export default SoundCloud;
