import LazyImg from "./LazyImg";
import LazyImgSlow from "./LazyImgSlow";
import LazyBackground from "./LazyBackground";
import LazyBackgroundSlow from "./LazyBackgroundSlow";
export function lazyHandler() {
    // new LazyImg();
    // new LazyImgSlow();
    new LazyBackground();
    new LazyBackgroundSlow();
}
