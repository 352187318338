import $ from "jquery";

class Fundraising {
    constructor(el) {
        this.el = el;
        this.fundraisingCartStorageKey = "sob-fundraising-cart";
        this.cartList = document.getElementById('fundraising_cart');
        this.formCartField = document.getElementById('mail_field_fundraising_cart');
    }

    init() {
        if(this.el) {
            let fundraisingImages = this.el.getElementsByClassName("fundraising-image-wrapper");
            Array.prototype.filter.call(fundraisingImages, function(fundraisingImage){
                $(fundraisingImage).find('img.svg').each(function(){
                    let $img = $(this);
                    let imgID = $img.attr('id');
                    let imgClass = $img.attr('class');
                    let imgURL = $img.attr('src');

                    $.get(imgURL, function(data) {
                        // Get the SVG tag, ignore the rest
                        let $svg = $(data).find('svg');

                        // Add replaced image's ID to the new SVG
                        if(typeof imgID !== 'undefined') {
                            $svg = $svg.attr('id', imgID);
                        }
                        // Add replaced image's classes to the new SVG
                        if(typeof imgClass !== 'undefined') {
                            $svg = $svg.attr('class', imgClass+' replaced-svg');
                        }

                        // Remove any invalid XML tags as per http://validator.w3.org
                        $svg = $svg.removeAttr('xmlns:a');

                        // Replace image with new SVG
                        $img.replaceWith($svg);

                    }, 'xml');

                });
            });



            let fundraisingObjects = this.el.getElementsByClassName("fundraising-object");
            let count = 1;
            Array.prototype.filter.call(fundraisingObjects, function(fundraisingObject){
                let fundraisingObjectInner = fundraisingObject.getElementsByClassName("fundraising-object-inner")[0];
                let closeDetail = fundraisingObject.getElementsByClassName("fundraising-close-detail")[0];
                let objectOverlay = fundraisingObject.getElementsByClassName("fundraising-object-overlay")[0];
                let index = count;
                fundraisingObjectInner.addEventListener("click", function () {
                    if (fundraisingObject.classList.contains("open-detail")){
                        fundraisingObject.classList.remove("open-detail");
                        document.body.classList.remove("no-scroll");
                    } else {
                        fundraisingObject.classList.add("open-detail");
                        document.body.classList.add("no-scroll");
                    }
                });
                closeDetail.addEventListener("click", function () {
                    fundraisingObject.classList.remove("open-detail");
                    document.body.classList.remove("no-scroll");
                });
                objectOverlay.addEventListener("click", function () {
                    fundraisingObject.classList.remove("open-detail");
                    document.body.classList.remove("no-scroll");
                });

                fundraisingObjectInner.addEventListener("mouseover", function () {
                    let line = document.getElementById("fundraising_plan_line_" + index);
                    if (line){
                        line.classList.add("active");
                    }
                });

                fundraisingObjectInner.addEventListener("mouseout", function () {
                    let line = document.getElementById("fundraising_plan_line_" + index);
                    if (line){
                        line.classList.remove("active");
                    }
                });

                count ++;
            });

            this.updateFundraisingCart();

            let f = this;

            let fundraisingObjectCartButtons = this.el.getElementsByClassName("fundraising-object-cart-button");
            Array.prototype.filter.call(fundraisingObjectCartButtons, function(fundraisingObjectCartButton){
                let itemId = fundraisingObjectCartButton.id.replace("add_to_cart_", "");
                fundraisingObjectCartButton.addEventListener("click", function () {
                    f.putItemInFundraisingCart(itemId);
                })
            });
        }
    }

    updateFundraisingCart(){
        if (this.cartList){
            this.updateFundraisingCartList();
        }

        if (this.formCartField){
            this.updateFundraisingCartField();
        }
    }


    updateEventListener() {
        let f = this;
        let fundraisingCartItemRemoveButtons = this.cartList.getElementsByClassName("fundraising-cart-item-remove");
        Array.prototype.filter.call(fundraisingCartItemRemoveButtons, function(fundraisingCartItemRemoveButton){
            fundraisingCartItemRemoveButton.addEventListener("click", function () {
                let itemId = fundraisingCartItemRemoveButton.id.replace("remove_", "");
                f.removeItemFromCart(itemId);
            })
        });

        let fundraisingCartItemCountSelects = this.cartList.getElementsByClassName("fundraising-cart-item-count");
        Array.prototype.filter.call(fundraisingCartItemCountSelects, function(fundraisingCartItemCountSelect){
            fundraisingCartItemCountSelect.addEventListener("change", function () {
                let itemId = fundraisingCartItemCountSelect.id.replace("update_", "");
                f.updateItemCountInFundraisingCart(itemId, fundraisingCartItemCountSelect.value);
            })
        });

        let fundraisingCartItemFreeContributionInputs = this.cartList.getElementsByClassName("fundraising-free-contribution");
        Array.prototype.filter.call(fundraisingCartItemFreeContributionInputs, function(fundraisingCartItemFreeContributionInput){
            fundraisingCartItemFreeContributionInput.addEventListener("change", function () {
                let itemId = fundraisingCartItemFreeContributionInput.id.replace("update_", "");
                f.updateItemFreeContributionInputInFundraisingCart(itemId, fundraisingCartItemFreeContributionInput.value);
            })
        });
    }

    removeItemFromCart(objectKey) {
        let fundraisingCart = localStorage.getItem(this.fundraisingCartStorageKey);
        fundraisingCart = JSON.parse(fundraisingCart);
        let existingItemId = this.getItemById(objectKey);

        fundraisingCart.cartEntries.splice(existingItemId, 1);

        localStorage.setItem(this.fundraisingCartStorageKey, JSON.stringify(fundraisingCart));
        this.updateFundraisingCartList();
    }


    updateItemCountInFundraisingCart(objectKey, newCount) {
        let fundraisingCart = localStorage.getItem(this.fundraisingCartStorageKey);
        fundraisingCart = JSON.parse(fundraisingCart);
        let existingItemId = this.getItemById(objectKey);

        if (existingItemId > -1) {
            if (newCount > 0){
                fundraisingCart.cartEntries[existingItemId].count = newCount;
            }

            localStorage.setItem(this.fundraisingCartStorageKey, JSON.stringify(fundraisingCart));
            this.updateFundraisingCartList();
        }
    }

    updateItemFreeContributionInputInFundraisingCart(objectKey, newCount) {
        let fundraisingCart = localStorage.getItem(this.fundraisingCartStorageKey);
        fundraisingCart = JSON.parse(fundraisingCart);
        let existingItemId = this.getItemById(objectKey);

        if (existingItemId > -1) {
            if (newCount > 0){
                fundraisingCart.cartEntries[existingItemId].freeContribution = newCount;
            }

            localStorage.setItem(this.fundraisingCartStorageKey, JSON.stringify(fundraisingCart));
            this.updateFundraisingCartList();
        }
    }

    putItemInFundraisingCart(objectKey) {
        let fundraisingCart = localStorage.getItem(this.fundraisingCartStorageKey);
        fundraisingCart = JSON.parse(fundraisingCart);
        let existingItemId = this.getItemById(objectKey);
        let object = document.getElementById('entry_' + objectKey);
        let count = object.getElementsByClassName('fundraising-object-count')[0];
        let title = object.getElementsByClassName('fundraising-object-title')[0].textContent;
        let imageUrl = object.getElementsByClassName('fundraising-object-image')[1].src;
        let lead = object.getElementsByClassName('fundraising-object-lead')[0].textContent;
        let price = object.getElementsByClassName('fundraising-object-price')[0];
        let freeContribution = object.getElementsByClassName('fundraising-free-contribution')[0];

        if (existingItemId > -1) {
            // update item
            let currentCount = fundraisingCart.cartEntries[existingItemId].count;
            let updatedCount = parseInt(currentCount);
            if (count){
                updatedCount += + parseInt(count.value);
                if (updatedCount >= 9 && !freeContribution){
                    updatedCount =  9;
                }
            }
            if (freeContribution){
                let currentFreeContribution = fundraisingCart.cartEntries[existingItemId].freeContribution;
                fundraisingCart.cartEntries[existingItemId].freeContribution = parseInt(freeContribution.value)+parseInt(currentFreeContribution);
            }
            fundraisingCart.cartEntries[existingItemId].count = updatedCount + "";
            fundraisingCart.cartEntries[existingItemId].title = title;
            fundraisingCart.cartEntries[existingItemId].imageUrl = imageUrl;
            fundraisingCart.cartEntries[existingItemId].lead = lead;
            fundraisingCart.cartEntries[existingItemId].price = price?price.textContent:"";
            fundraisingCart.cartEntries[existingItemId].currency = price?price.dataset.currency:freeContribution?freeContribution.dataset.currency:"";
            localStorage.setItem(this.fundraisingCartStorageKey, JSON.stringify(fundraisingCart));
        } else {
            // add new item
            let newCartItem =
                {
                    id: objectKey,
                    count: count?count.value:1,
                    title: title,
                    imageUrl: imageUrl,
                    price: price?price.textContent:0,
                    lead: lead,
                    currency: price?price.dataset.currency:freeContribution?freeContribution.dataset.currency:"",
                    freeContribution: freeContribution?freeContribution.value:""
                };

            fundraisingCart.cartEntries.push(newCartItem);

            // Store data
            localStorage.setItem(this.fundraisingCartStorageKey, JSON.stringify(fundraisingCart));
        }

        // init count
        if (count){count.value = 1;}
        if (freeContribution){freeContribution.value = "";}

        this.updateFundraisingCartList();
        object.getElementsByClassName('fundraising-object-inner')[0].click();

        let parentOffsetTop = document.getElementById("wrapper").offsetTop + document.getElementById("content-main-wrap").offsetTop;
        $("body,html").animate({
            scrollTop: parentOffsetTop + this.cartList.offsetTop - 100
        }, 500);
    }

    getItemById(id) {
        let fundraisingCart = localStorage.getItem(this.fundraisingCartStorageKey);
        fundraisingCart = JSON.parse(fundraisingCart);

        if (fundraisingCart.cartEntries.length > 0) {
            for (let i = 0; i < fundraisingCart.cartEntries.length; i += 1) {
                if (fundraisingCart.cartEntries[i].id == id) {
                    return i;
                }
            }
        }

        return -1;
    }

    updateFundraisingCartList() {
        let fundraisingCart = localStorage.getItem(this.fundraisingCartStorageKey);

        let cartContent = "";

        if (fundraisingCart){

            fundraisingCart = JSON.parse(fundraisingCart);
            let cartEntries = fundraisingCart.cartEntries;
            let total = 0;
            let currency = 0;

            if (cartEntries.length > 0){
                this.cartList.classList.add("with-entries");
                let readOnly = this.cartList.classList.contains('read-only');

                for (let i=0; i<cartEntries.length; i++) {
                    cartContent += '<div class="fundraising-cart-item"> ';
                    if (readOnly){
                        // if (!cartEntries[i].freeContribution) {
                            cartContent += '<div class="fundraising-cart-item-count">' + cartEntries[i].count + '</div>';
                        // } else {
                        //     cartContent += '<div class="fundraising-cart-item-count">' + cartEntries[i].freeContribution + " " + cartEntries[i].currency + '</div>';
                        // }
                    }
                    cartContent += '<div class="fundraising-cart-item-image" style="background-image: url('+ cartEntries[i].imageUrl +')"></div>' +
                        '<div class="fundraising-cart-item-description"><p>' + cartEntries[i].title + '</p></div>';

                    if (!readOnly){
                        if (!cartEntries[i].freeContribution){
                            cartContent += '<select class="fundraising-cart-item-count" id="update_'+cartEntries[i].id+'">';
                            for (let j=1; j<=9; j++) {
                                cartContent += '<option value="'+j+'"';
                                if (j == cartEntries[i].count){
                                    cartContent += ' selected';
                                }
                                cartContent += '>'+j+'</option>';
                            }
                            cartContent += '</select>';
                        } else {
                            cartContent += "<label id=\"fundraising-object-free-contribution-input\">" +
                                "           <input id=\"update_"+cartEntries[i].id+"\" type=\"text\" class=\"fundraising-free-contribution\" name=\"object-nb\" value=\""+cartEntries[i].freeContribution+"\">" +
                                "           <span>"+cartEntries[i].currency+"</span>" +
                                "           </label>";
                        }
                    } else {
                        if (!cartEntries[i].freeContribution) {
                            cartContent += '<div class="fundraising-cart-item-count">' + cartEntries[i].currency + " " + parseInt(cartEntries[i].count) * parseInt(cartEntries[i].price) + '</div>';
                            total += parseInt(cartEntries[i].count) * parseInt(cartEntries[i].price);
                        } else {
                            cartContent += '<div class="fundraising-cart-item-count">' + cartEntries[i].currency + " " + cartEntries[i].freeContribution + '</div>';
                            total += parseInt(cartEntries[i].freeContribution);
                        }
                        currency = cartEntries[i].currency;
                    }
                    cartContent += '<div class="fundraising-cart-item-remove" id="remove_'+cartEntries[i].id+'">' +
                        '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="27px" height="27px" viewBox="0 0 357 357" style="enable-background:new 0 0 357 357;" xml:space="preserve"><g class="close_svg"><polygon points="357,35.7 321.3,0 178.5,142.8 35.7,0 0,35.7 142.8,178.5 0,321.3 35.7,357 178.5,214.2 321.3,357 357,321.3\n' +
                        '\t\t\t214.2,178.5 \t\t"/>    </g></svg></div>' +
                        '</div>';
                }

                if (readOnly) {
                    cartContent += "<div class='fundraising-cart-total fundraising-cart-item'><div class=\"fundraising-cart-item-description\"></div><div class=\"fundraising-cart-item-count\">"+ currency + " " + total + "</div></div>"
                }
            } else {
                this.cartList.classList.remove("with-entries");
            }
        } else {
            let fundraisingCart = {
                lastUpdate: new Date(),
                cartEntries:[]
            };
            localStorage.setItem(this.fundraisingCartStorageKey, JSON.stringify(fundraisingCart));
        }

        this.cartList.getElementsByClassName("fundraising-cart-list-inner")[0].innerHTML = cartContent;
        this.updateEventListener();
    }

    updateFundraisingCartField() {
        let fundraisingCart = localStorage.getItem(this.fundraisingCartStorageKey);
        let cartContent = "";

        if (fundraisingCart){
            fundraisingCart = JSON.parse(fundraisingCart);
            let cartEntries = fundraisingCart.cartEntries;

            for (let i=0; i<cartEntries.length; i++) {
                cartContent += cartEntries[i].count + ' x ' +cartEntries[i].title;
                if (!cartEntries[i].freeContribution){
                    cartContent +=  "\t\t" + cartEntries[i].currency + " " + parseInt(cartEntries[i].count) * parseInt(cartEntries[i].price);
                } else {
                    cartContent +=  "\t\t" + cartEntries[i].currency + " " + cartEntries[i].freeContribution;
                }
                cartContent += '\r';
            }
        }

        this.formCartField.value = cartContent;
    }

    resetFundraisingCart() {
        let fundraisingCart = {
            lastUpdate: new Date(),
            cartEntries:[]
        };

        localStorage.setItem(this.fundraisingCartStorageKey, JSON.stringify(fundraisingCart));
    }

}
export default Fundraising;
